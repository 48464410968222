function PcChatPacketManager() {}

PcChatPacketManager.readPacket = function(data) {
  try {
    var packetData = typeof(data) == "string" ? JSON.parse(data) : data;
    if ("className" in packetData) {
      if (packetData.className == "MessagePacket" ) {
        packetData.messageType = packetData.messageType | packetData.senderType;
        packetData.additionalData = packetData.additionalData || {};
      }
      else if (packetData.className == "ViewerStatePacket") {
        packetData.currentViewerTariffType = parseInt(packetData.currentViewerTariffType);
      }
      return packetData;
    }
    return null;
  } catch(e) {
    console.log("Error parsing packet data: ", data);
    return null;
  }
};

PcChatPacketManager.PmAuthorizationPacket = function(customParams) {
  this.className = "PmAuthorizationPacket";
  this.customParams = customParams;
};

PcChatPacketManager.PingPacket = function() {
  this.className = "PingPacket";
};

PcChatPacketManager.PmGetDialogsPacket = function() {
  this.className = "PmGetDialogsPacket";
};

PcChatPacketManager.PmCloseDialogPacket = function(receiver, receiverId) {
  this.className = "PmCloseDialogPacket";
  this.receiver = receiver;
  this.receiverId = receiverId;
};

PcChatPacketManager.PmGetHistoryPacket = function(receiver, receiverId, page, limit) {
  this.className = "PmGetHistoryPacket";
  this.receiver = receiver;
  this.receiverId = receiverId;
  this.page = page;
  this.limit = limit;
};

PcChatPacketManager.PmStartCallPacket = function(receiver, receiverId) {
  this.className = "PmStartCallPacket";
  this.receiver = receiver;
  this.receiverId = receiverId;
};

PcChatPacketManager.PmStopCallPacket = function(receiver, receiverId) {
  this.className = "PmStopCallPacket";
  this.receiver = receiver;
  this.receiverId = receiverId;
};

PcChatPacketManager.PmAcceptCallPacket = function(receiver, receiverId) {
  this.className = "PmAcceptCallPacket";
  this.receiver = receiver;
  this.receiverId = receiverId;
};

PcChatPacketManager.PmDeclineCallPacket = function(receiver, receiverId) {
  this.className = "PmDeclineCallPacket";
  this.receiver = receiver;
  this.receiverId = receiverId;
};

PcChatPacketManager.PmMessagePacket = function (receiver, receiverId, message) {
  this.className = "PmMessagePacket";
  this.receiver = receiver;
  this.receiverId = receiverId;
  this.message = message;
};

PcChatPacketManager.PmFilePacket = function (receiver, receiverId, fileId, name, origName, mimeType, size) {
  this.className = "PmFilePacket";
  this.receiver = receiver;
  this.receiverId = receiverId;
  this.fileId = fileId;
  this.name = name;
  this.origName = origName;
  this.mimeType = mimeType;
  this.size = size;
};

PcChatPacketManager.PmCustomInfoPacket = function (receiver, receiverId, info) {
  this.className = "PmCustomInfoPacket";
  this.receiver = receiver;
  this.receiverId = receiverId;
  this.info = info;
};

PcChatPacketManager.WebrtcOfferAnswerPacket = function(sdpType, sdp, receiverId) {
  this.className = "WebrtcOfferAnswerPacket";
  this.sdpType = sdpType;
  this.sdp = sdp;
  this.receiverId = receiverId;
};